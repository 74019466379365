<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		form(@submit.prevent="submit", v-if="admin")
			b-tabs(content-class="mt-3", pills, card)
				b-tab(title="Main", style="padding: 0")
					.row
						.col-md-6
							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label Admin Information <span v-if="admin.id">({{ admin.id }})</span>
									.card-toolbar
								.card-body
									.row
										.col-sm-4
											.form-group
												label First Name
												input.form-control(type="text", v-model="admin.first_name")
										.col-sm-4
											.form-group
												label First Name
												input.form-control(type="text", v-model="admin.last_name")
							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label Login
									.card-toolbar
								.card-body
									.form-group
										label Email
										input.form-control(type="text", v-model="admin.email")
									.form-group
										label Password
										input.form-control.hidden-field(type="password", name="password")
										input.form-control(type="password", v-model="newPass")
										p.help-block Leave blank to keep current value



</template>
<style lang="scss">
.hidden-field {
	opacity: 0; position: absolute;
	pointer-events: none; z-index: -1px;
	width: 1px; height: 1px;
	overflow: hidden;
}
</style>
<script>
import Vue from 'vue';
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import store from "@/core/services/store";
import {OVERRIDE_PAGE_LAYOUT_CONFIG, SET_LAYOUT_CONFIG} from "@/core/services/store/config.module";
import HtmlClass from "@/core/services/htmlclass.service";

async function loadRoute(vm, to, from, next){

	let admin = {};
	if (to.name === 'admin-edit') {
		let entityId = +to.params.id;
		try {
			admin = await Vue.$dwData.admin.get(entityId, true);
		} catch (e) {
			Vue.$notify.error('Failed to load admin');
			return next(false);
		}
	} else {
		admin = Vue.$dwData.admin.newInstance();
	}
	return vm => {
		vm.admin = admin;
		vm.busy = false;
		return vm;
	};
}

export default {
	async beforeRouteEnter(to, from, next){
		let bind = await loadRoute({}, to, from, next);
		next(bind);
	},
	async beforeRouteUpdate(to, from, next){
		await loadRoute(this, to, from, next);
		next();
	},
	name: 'AdminForm',
	data(){
		return {
			busy: true,
			admin: null,
			newPass: '',
		};
	},
	methods: {

		async submit(closeAfterSave = false){
			if (this.busy){
				return;
			}
			this.busy = true;
			let data = Vue.util.extend({}, this.admin);
			data.new_pass = this.newPass;
			try {
				let resp = await this.$api.post('admins/save', data);
				this.busy = false;
				this.$notify.success('Admin has been saved');
				if (closeAfterSave) {
					this.$router.push({name: 'admin-list'});
				} else {
					this.publisher = resp.entity;
				}
			} catch (e) {
				console.error(e);
				this.busy = false;
			}
		}
	},
	mounted(){
		let vm = this;
		this.$store.dispatch(SET_BREADCRUMB, [
			{title: 'Media'},
			{title: 'Admins', name: 'admin-list'},
			{title: this.admin && this.admin.id ? 'Edit Admin' : 'Add Admin'}
		]);
		this.$store.dispatch('setBackButton', {
			route: {name: 'admin-list'}
		});
		this.$store.dispatch('setPageButtons', [
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save',
				action(){
					vm.submit();
				}
			},
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save & Close',
				action(){
					vm.submit(true);
				}
			}
		]);
	},
}

</script>
